import React from "react";
import { useNavigate } from "react-router-dom";
import { EnquiryForm } from "../Footer";
import Header from "../components/Header/Header";

const ContactUsHeader = () => {
  return (
    <div className="relative">
      <img
        src={"/images/contact.jpg"}
        alt={`hedge architects contact information in ludhiana, punjab`}
        className="max-sm:w-full max-sm:h-[280px] w-full h-[580px] "
      />
      <div
        className="top-[20%] max-sm:top-[50%]
        absolute left-0 right-0 mx-auto w-full text-center "
        style={{
          position: "absolute",
        }}
      >
        <h1 className="max-sm:text-4xl max-sm:tracking-[4px] text-7xl font-semibold font-sans tracking-[8px] text-white">
          CONTACT US
        </h1>
      </div>
    </div>
  );
};

const ContactUs: React.FC = () => {
  const navigate = useNavigate();

  const phoneNumber = "7710559682";

  return (
    <div
      style={{
        background:
          "linear-gradient(to bottom, rgba(237, 235, 219, 0.8),rgba(136, 135, 126, 0.5))",
      }}
    >
      <Header
        isScrolled
        title="Home"
        onClick={() => {
          navigate("/");
        }}
      />

      <ContactUsHeader />
      <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-6 lg:gap-16 p-6">
        <div className="flex flex-col items-start">
          <h3 className="font-bold uppercase text-lg mt-[-12px]">Socials</h3>
          <div
            className="flex cursor-pointer"
            onClick={() => {
              window.location.href =
                "https://www.instagram.com/hedgearchitects/";
            }}
          >
            <img
              src={"/insta-logo.png"}
              alt={"hedge architects instagram in ludhiana, punjab"}
              className="w-[30px] h-[30px]"
            />
            <h2
              className="text-sm place-content-center pl-2 font-medium"
              aria-label=""
            >
              Instagram
            </h2>
          </div>
        </div>
        <div className="flex flex-col items-start">
          <h3 className="font-bold uppercase text-lg">Phone</h3>
          <h2
            className="text-sm cursor-pointer font-medium"
            onClick={() => {
              window.location.href = `tel:+91${phoneNumber}`;
            }}
          >
            +91 {phoneNumber}
            <br />
            &nbsp;
          </h2>
        </div>

        <div className="flex flex-col items-start">
          <h3 className="font-bold uppercase text-lg">Email</h3>
          <h2 className="text-sm pb-5 font-medium">vidhigarg82@gmail.com</h2>
        </div>
        <div className="flex flex-col items-start">
          <h3 className="font-bold uppercase text-lg">Address</h3>
          <h2 className="text-sm font-medium">
            10A, Basant Avenue, Near Shiv Vatika Mandir,
            <br />
            Ludhiana, Punjab 141013
          </h2>
        </div>
      </div>

      <div className="max-sm:flex-col flex flex-row  justify-between max-sm:p-4 p-16">
        <iframe
          title="hedge architects locations"
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d853.344810173375!2d75.82663190054915!3d30.852245542701286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDUxJzA3LjgiTiA3NcKwNDknMzUuMyJF!5e0!3m2!1sen!2sin!4v1733385087951!5m2!1sen!2sin"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="rounded-lg w-[55%] h-[500px] max-sm:w-full max-sm:h-[300px]"
        ></iframe>
        <div className="max-sm:pt-8">
          <EnquiryForm />
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
