import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import "./EnquiryForm.css";

const EnquiryForm: React.FC = () => {
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [submit, setSubmit] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSubmit(true);
    const formData = {
      subject: `${name} sent an enquiry`,
      phone: phone,
      message: message,
    };

    fetch("https://formspree.io/f/mnnqrpvo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          setSubmit(false);

          alert("Message sent successfully!");
          setPhone("");
          setName("");
          setMessage("");
        } else {
          setSubmit(false);

          alert("Error sending message. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setSubmit(false);

        alert("Error sending message. Please try again.");
      });
  };

  return (
    <div className="form-container bg-black bg-opacity-10">
      <h2 className="form-title font-sans">Enquiry</h2>
      <form onSubmit={handleSubmit} className="form w-[400px] max-sm:w-full">
        <div className="input-group">
          <label htmlFor="name" className="label">
            Name *
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="input"
          />
        </div>
        <div className="input-group">
          <label htmlFor="email" className="label">
            Phone Number *
          </label>
          <input
            type="text"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
            className="input"
          />
        </div>
        <div className="input-group">
          <label htmlFor="message" className="label">
            Your Message
          </label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            className="textarea"
          />
        </div>
        <button
          type="submit"
          className="submit-button"
          disabled={submit}
          style={{
            opacity: submit ? 0.5 : 1,
          }}
        >
          {submit ? (
            <ClipLoader color="white" loading={true} size={16} />
          ) : (
            "SEND"
          )}
        </button>
      </form>
    </div>
  );
};

export default EnquiryForm;
