import React from "react";
import { useSpring, animated } from "@react-spring/web";

declare type AnimationEntryProps = {
  children: JSX.Element;
  hasShownEntryAnimation: boolean;
};

const AnimationEntry: React.FC<AnimationEntryProps> = ({
  children,
  hasShownEntryAnimation,
}) => {
  const imageAnimation = useSpring({
    from: { opacity: 0, transform: "translateY(20px)" },
    to: { opacity: 1, transform: "translateY(0px)" },
    config: { tension: 70, friction: 22 },
    delay: 100,
  });

  // Animation for the text
  const textAnimation = useSpring({
    from: { opacity: 0, transform: "translateY(0px)" },
    to: { opacity: 1, transform: "translateY(0px)" },
    config: { tension: 120, friction: 14 },
    delay: 1000,
  });

  return (
    <>
      {!hasShownEntryAnimation ? (
        <div
          className="items-center justify-center h-[100svh]"
          style={{
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            // justifyContent: "center",
            // height: "100vh",
            textAlign: "center",
          }}
        >
          <animated.img
            src="/hlogo.jpeg"
            alt="Fading In"
            style={{
              ...imageAnimation,
              borderRadius: "8px",
            }}
            className="max-sm:w-[100px] max-sm:h-[90px] w-[130px] h-[130px]"
          />

          {/* Animated Text */}
          <animated.div style={textAnimation}>
            <p
              className="mt-4 max-sm:text-2xl md:text-4xl font-bold text-primary font-mono"
              style={{
                letterSpacing: "5px",
              }}
            >
              HEDGE ARCHITECTS
            </p>
          </animated.div>
        </div>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
};
export default AnimationEntry;
