import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { EffectFade, Autoplay, Pagination } from "swiper/modules";
import "./carousel.css";
import { imageData } from "../constants";
import Header from "../components/Header/Header";
import { useNavigate } from "react-router-dom";

const CrossfadeCarousel: React.FC = () => {
  const navigate = useNavigate();
  // const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const [isTextVisible, setIsTextVisible] = useState(true);

  const handleSlideChange = (swiper: any) => {
    setIsTextVisible(false);
    // setTimeout(() => {
    //   setActiveSlideIndex(swiper.realIndex);
    // }, 200);
    setTimeout(() => {
      setIsTextVisible(true);
    }, 1500);
  };

  return (
    <div className="w-screen h-[100svh] overflow-hidden relative fade-in">
      <Header
        title="Contact Us"
        onClick={() => {
          navigate("/contact-us");
        }}
        isScrolled
      />
      <Swiper
        modules={[EffectFade, Autoplay, Pagination]}
        effect="fade"
        fadeEffect={{ crossFade: true }}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
          waitForTransition: false,
        }}
        pagination={{ clickable: true }}
        loop={true}
        slidesPerView={1}
        spaceBetween={0}
        speed={2000}
        onSlideChangeTransitionStart={handleSlideChange}
        style={{ width: "100%", height: "100%" }}
      >
        {imageData.map((item, index) => (
          <SwiperSlide key={`slide-${index}`}>
            <div className="absolute w-full h-full transition-opacity duration-2000 ease-in-out">
              <img
                src={`/${item.src}`}
                alt={item.alt}
                className="w-full h-full object-cover"
              />
              <div
                className={`max-sm:w-[90%] absolute bottom-14 left-5 text-white p-4 rounded transition-opacity duration-2000 bg-black shadow-md shadow-black/50 bg-opacity-30 ${
                  isTextVisible ? "opacity-100" : "opacity-0"
                }`}
              >
                <p className="text-3xl max-sm:text-2xl font-sans">
                  {item.heading}
                </p>
                <p className="pt-2 text-xl max-sm:text-[18px] font-sans">
                  {item.subHeading}
                  <br />
                </p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CrossfadeCarousel;
