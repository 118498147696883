import React from "react";
import { useSpring, animated } from "@react-spring/web";

declare type WrapperProps = {
  children: JSX.Element;
  showContentAnimation: boolean;
};

const Wrapper: React.FC<WrapperProps> = ({
  children,
  showContentAnimation,
}) => {
  const mainContentAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 500 }, // Animation duration in milliseconds
    delay: 50, // Optional delay for the fade-in
  });

  return (
    <>
      <animated.div
        style={!showContentAnimation ? mainContentAnimation : undefined}
      >
        {children}
      </animated.div>
    </>
  );
};
export default Wrapper;
