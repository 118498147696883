import React from "react";
import "./text.css";
import clsx from "clsx";

declare type TextProps = {
  value: string;
} & React.HTMLProps<HTMLParagraphElement>;

const CustomText: React.FC<TextProps> = ({
  onClick,
  value,
  style,
  className,
}) => {
  return (
    <h2
      className={clsx(
        "animated-underline text-[14px] font-sans font-medium",
        className,
      )}
      style={style}
      onClick={onClick}
    >
      {value}
    </h2>
  );
};
export default CustomText;
