import React from "react";
import { Carousel } from "../Carousel";

const MainContent: React.FC = () => {
  return (
    <>
      <Carousel />
    </>
  );
};
export default MainContent;
