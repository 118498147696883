import React, { useEffect, useState } from "react";
import SecondMount from "./Mount/SecondMount";
import {
  BrowserRouter as Router1,
  Routes,
  Route,
  Navigate,
  StaticRouter,
} from "react-router-dom";
import ContactUs from "./Contact Us/contact-us";
import AnimationEntry from "./Mount/AnimationEntry";
import Wrapper from "./Mount/Wrapper";

import "./index.css";
import ScrollToTop from "./Mount/ScrollToTop";
import MetaComp from "./Mount/MetaComp";
import usePreloadImages from "./hooks/usePreloadImages";

const App: React.FC = () => {
  usePreloadImages();
  const [hasShownEntryAnimation, setHasShownEntryAnimation] = useState(false);
  const [showContentAnimation, setShowContentAnimation] = useState(false);

  useEffect(() => {
    const entryAnimationTimerInMS = 4000;
    const contentAnimationTimerInMS = entryAnimationTimerInMS + 1000;

    const timer = setTimeout(() => {
      if (!hasShownEntryAnimation) {
        setHasShownEntryAnimation(true);
      }
    }, entryAnimationTimerInMS);

    const contentAnimationTimer = setTimeout(() => {
      setShowContentAnimation(true);
    }, contentAnimationTimerInMS);

    return () => {
      clearTimeout(timer);
      clearTimeout(contentAnimationTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Router: React.ComponentType<any> = process.env.REACT_SNAP
    ? StaticRouter
    : Router1;

  return (
    <Router>
      <ScrollToTop />
      <AnimationEntry hasShownEntryAnimation={hasShownEntryAnimation}>
        <Routes>
          <Route
            path="/"
            element={
              <Wrapper showContentAnimation={showContentAnimation}>
                <React.Fragment>
                  <MetaComp
                    ogURL="/"
                    title="Hedge Architects | Best architect in Ludhiana, Punjab"
                  />
                  <SecondMount
                    hasShownEntryAnimation={hasShownEntryAnimation}
                    setHasShownEntryAnimation={setHasShownEntryAnimation}
                  />
                </React.Fragment>
              </Wrapper>
            }
          />
          <Route
            path="/contact-us"
            element={
              // <Wrapper showContentAnimation={showContentAnimation}>
              <React.Fragment>
                <MetaComp
                  ogURL="/contact-us"
                  title="Contac Us | Hedge Architects"
                />
                <ContactUs />
              </React.Fragment>
              // </Wrapper>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AnimationEntry>
    </Router>
  );
};

export default App;
