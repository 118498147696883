import clsx from "clsx";
import React, { useEffect, useState } from "react";
import CustomText from "../Text/Text";

declare type HeaderProps = {
  isScrolled?: boolean;
  setIsScrolled?: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  onClick: () => void;
};

const Header: React.FC<HeaderProps> = ({
  isScrolled,
  setIsScrolled,
  title,
  onClick,
}) => {
  const [headerScrolled, setHeaderScrolled] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 50) {
      // Change the header color when scrolled past 50px
      setHeaderScrolled(true);
      setIsScrolled && setIsScrolled(true);
    } else {
      // Revert to transparent if above 50px
      setHeaderScrolled(false);

      setIsScrolled && setIsScrolled(false);
    }
  };

  // Add event listener for clicks outside the menu
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <header
      className={clsx(
        "max-sm:py-2 fixed top-0 left-0 w-full py-4 px-6 z-50",
        headerScrolled || isScrolled
          ? "bg-black bg-opacity-40"
          : "bg-transparent",
      )}
    >
      <div className="flex justify-end">
        <nav>
          <CustomText
            value={title}
            className={clsx(
              "!text-2xl max-sm:!text-[18px]",
              headerScrolled || isScrolled ? "!text-white" : "text-black",
            )}
            onClick={onClick}
          />
        </nav>
      </div>
    </header>
  );
};
export default Header;
