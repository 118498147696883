import React from "react";
import { Helmet } from "react-helmet"; // Import Helmet

declare type MetaCompProps = {
  ogURL: string;
  title: string;
};
const MetaComp: React.FC<MetaCompProps> = ({ ogURL, title }) => {
  const hedgeArchUrl = "https://www.hedgearchitects.com/";
  const locUrl = window.location.href.includes("netlify.app")
    ? window.location.href
    : hedgeArchUrl;
  const url = locUrl.replace(/\/$/, ""); // Removes all slashes

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "Hedge Architects",
    image: "https://hedgearchitects.com/hedge-logo.jpeg",
    url: "https://hedgearchitects.com",
    telephone: "+917710559682",
    address: {
      "@type": "PostalAddress",
      streetAddress: "10A, Basant Avenue, Near Shiv Vatika Mandir",
      addressLocality: "Ludhiana",
      addressRegion: "Punjab",
      postalCode: "141003",
      addressCountry: "IN",
    },
    openingHours: "Mo-Su 09:00-19:00",
    sameAs: ["https://www.instagram.com/hedgearchitects/"],
    geo: {
      "@type": "GeoCoordinates",
      latitude: "30.85216906544466",
      longitude: "75.82647353373385",
    },
  };

  return (
    <Helmet>
      <title>{title}</title>

      <meta
        name="description"
        content="Explore the best architectural services in Ludhiana, Punjab. We create designs that harmonize creativity and utility, transforming ordinary spaces into extraordinary places."
      />

      {/* Location */}
      <link
        rel="alternate"
        hrefLang="en-in"
        href={`https://hedgearchitects.com${ogURL}`}
      />

      {/* Sitemap */}
      <link
        rel="sitemap"
        type="application/xml"
        href={`${hedgeArchUrl}/sitemap.xml`}
      />

      <link
        rel="sitemap"
        type="application/xml"
        href={`${hedgeArchUrl}/image-sitemap.xml`}
      />

      <link rel="canonical" href={`${url}${ogURL}`} />

      {/* Robot */}
      <meta name="robots" content="index, follow" />

      {/* JSON-LD structured data */}
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>

      {/* Keywords */}
      <meta
        name="keywords"
        content="ludhiana, punjab,top firm, top architects, innovative architect, latest architects, 
        architecture, design, modern, innovative, sustainable, modern design, sustainable buildings,
        modern architecture, sustainable design, architectural styles, residential architecture, 
        commercial architecture, landscape design, interior design, exterior design, urban planning, 
        building materials, architectural engineering, renovation projects, green architecture, 
        futuristic designs, minimalist architecture, contemporary homes, structural design, 
        smart buildings, historic preservation, modular architecture, architectural visualization"
      />

      {/* For Socials */}
      <meta
        property="og:title"
        content="Hedge Architects | Best architect in Ludhiana, Punjab"
      />
      <meta property="og:site_name" content="Hedge Architects" />
      <meta
        property="og:description"
        content="Explore the best architectural services in Ludhiana, Punjab. We create designs that harmonize creativity and utility, transforming ordinary spaces into extraordinary places."
      />
      <meta property="og:image" content={`${url}/hedge-logo.jpeg`} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta
        property="og:image:alt"
        content="Best creative architect in Ludhiana, Punjab"
      />
      <meta property="og:url" content={`${url}${ogURL}`} />
      <meta property="og:type" content="website" />

      {/* For Twitter  */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Hedge Architects | Best architect in Ludhiana, Punjab"
      />
      <meta
        name="twitter:description"
        content="Explore the best architectural services in Ludhiana, Punjab. We create designs that harmonize creativity and utility, transforming ordinary spaces into extraordinary places."
      />
      <meta name="twitter:image" content={`${url}/hedge-logo.jpeg`} />
    </Helmet>
  );
};
export default MetaComp;
