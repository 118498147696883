export const imageData = [
  {
    src: "images/skylight-residence.jpg",
    heading: "Skylight residence",
    subHeading: "Sculpting spaces and unveiling the essence of modern living",
    alt: "modern house, big entrance door, water feature at entrance, planters at building front, car porch ideas, tile pattern design on elevation, trendy houses, ludhiana, punjab",
  },
  {
    src: "images/courtyard-house.jpg",
    heading: "Courtyard house",
    subHeading: "A contemporary home rooted in classical aesthetics",
    alt: "white flower tree, tree in courtyard of building, arch shaped glass windows, classical european architecture, courtyard with pool inside, classical terrace design, tile design nearby pool ,ludhiana, punjab",
  },
  {
    src: "images/modern-arch-restaur-exterior.jpg",
    heading: "Modern architecture restaurant",
    subHeading: "Every step feels like walking with nature",
    alt: "landscape design, water feature design, restaurant design, building with big glasses, arch entrance, ludhiana, punjab",
  },
  {
    src: "images/entryway.jpg",
    heading: "Entryway",
    subHeading: "Inside a roman classical mansion",
    alt: "classical art pieces, candles on wall, moldings, brick pattern, classical railing, ludhiana, punjab",
  },
  {
    src: "images/clothing-brand-exterior.jpg",
    heading: "Clothing brand",
    subHeading:
      "The classical boutique reflects your personality from every corner.",
    alt: "french molding on wall, all white room, designer lounge, white fabric sofa set, ludhiana, punjab",
  },
  {
    src: "images/clothing-brand.jpg",
    heading: "Clothing brand",
    subHeading:
      "The classical boutique reflects your personality from every corner.",
    alt: "french molding on wall, all white room, designer lounge, white fabric sofa set, ludhiana, punjab",
  },
  {
    src: "images/house-with-sustainable-living.jpg",
    heading: "House of openings",
    subHeading: "A dream house with sustainable living",
    alt: "ideas of courtyard design, ludhiana, punjab",
  },
  {
    src: "images/santorini-paradise.jpg",
    heading: "Santorini paradise",
    subHeading: "Get lost into the charm of this effortlessly beautiful house",
    alt: "cute house, round entry gate, boundary wall ideas, wicked entry gate ideas, name plate ideas, house plan in different levels, metal windows, louver elevation, coloured louvers in elevation, arch pattern house, white house, house with lots of greenery, ludhiana, punjab",
  },
  {
    src: "images/private-rooftop-space.jpg",
    heading: "Private rooftop space",
    subHeading:
      "A pergola paradise where every corner tells story of transformation",
    alt: "shop elevation, french architecture, elevation with round arch, glass elevation, cute elevation for boutiques and cafes, ludhiana, punjab",
  },
  {
    src: "images/skylight-residence-exterior.jpg",
    heading: "Skylight residence",
    subHeading: "Sculpting spaces and unveiling the essence of modern living",
    alt: "modern house, big entrance door, water feature at entrance, planters at building front, car porch ideas, tile pattern design on elevation, trendy houses, ludhiana, punjab",
  },
  {
    src: "images/luxury-interiors.jpg",
    heading: "Luxury interiors",
    subHeading: "Modern interiors with elegance in every detail",
    alt: "wall decor ideas, trendy ceiling design, modern house plan, trendy entryways, modern architecture, modern interior, tree in courtyard, glass passage, modern furniture, modern wall panelling ideas, ludhiana, punjab",
  },
  {
    src: "images/modern-arch-restaur.jpg",
    heading: "Modern architecture restaurant",
    subHeading: "Every step feels like walking with nature",
    alt: "arch windows, square box pop, restaurant sitting pattern, lamp, chrome work, fluted pop on wall, round tables, fabric chair, chrome table, ludhiana, punjab",
  },
];
