import { useEffect } from "react";
import { imageData } from "../constants";

const usePreloadImages = () => {
  const preloadImages = (srcArray: string[]) => {
    srcArray.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  };

  useEffect(() => {
    const images = imageData.map((item) => item.src);
    preloadImages([...images, "/images/contact.jpg"]);
  }, []);
};
export default usePreloadImages;
