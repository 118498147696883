import React, { useState, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import MainContent from "./MainContent";

declare type SecondMountProps = {
  hasShownEntryAnimation: boolean;
  setHasShownEntryAnimation: React.Dispatch<React.SetStateAction<boolean>>;
};
const SecondMount: React.FC<SecondMountProps> = ({
  hasShownEntryAnimation,
  setHasShownEntryAnimation,
}) => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (hasShownEntryAnimation) {
      setShowContent(true);
    }

    const timer = setTimeout(() => {
      if (!hasShownEntryAnimation) {
        setShowContent(true);
        setHasShownEntryAnimation(true);
      }
    }, 4000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Animation for the image
  const imageAnimation = useSpring({
    from: { opacity: 0, transform: "translateY(20px)" },
    to: { opacity: 1, transform: "translateY(0px)" },
    config: { tension: 70, friction: 22 },
    delay: 100,
  });

  // Animation for the text
  const textAnimation = useSpring({
    from: { opacity: 0, transform: "translateY(0px)" },
    to: { opacity: 1, transform: "translateY(0px)" },
    config: { tension: 120, friction: 14 },
    delay: 1000,
  });

  // Animation for the main content (delayed by 5 seconds)
  const mainContentAnimation = useSpring({
    opacity: showContent ? 1 : 0,
    transform: showContent ? "translateY(0px)" : "translateY(0px)",
    config: { tension: 120, friction: 14 },
  });

  return (
    <>
      {!showContent && !hasShownEntryAnimation && (
        <div
          className="items-center justify-center h-[100svh]"
          style={{
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            // justifyContent: "center",
            // height: "100vh",
            textAlign: "center",
          }}
        >
          <animated.img
            src="/hlogo.jpeg"
            alt="Fading In"
            style={{
              ...imageAnimation,
              borderRadius: "8px",
            }}
            className="max-sm:w-[100px] max-sm:h-[90px] w-[130px] h-[130px]"
          />

          {/* Animated Text */}
          <animated.div style={textAnimation}>
            <p
              className="mt-4 max-sm:text-2xl md:text-4xl font-bold text-primary font-mono"
              style={{
                letterSpacing: "5px",
              }}
            >
              HEDGE ARCHITECTS
            </p>
          </animated.div>
        </div>
      )}

      {showContent && (
        <animated.div style={mainContentAnimation}>
          <MainContent />
        </animated.div>
      )}
    </>
  );
};

export default SecondMount;
